import React, { useEffect, useState,useRef } from 'react';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent'; // Add this import
import CardActions from '@mui/material/CardActions'; // Add this import
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Chip from '@mui/material/Chip';
import { useLocation, useNavigate } from 'react-router-dom';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ReadMoreIcon from '@mui/icons-material/ReadMore';
import AddSubjectForm from './AddSubjectForm';
import EditSubjectForm from './EditSubjectForm';
import { deleteSubject, getSubjectsForASyllabus, updateSubject } from '../../services/subjects/subjects';
import { Box } from '@mui/material';
import { uploadImage } from '../../services/file/image';
import Avatar from '@mui/material/Avatar';
import { useSelector } from 'react-redux';

function SubjectsPage({  syllabusId, selectedLanguages }) {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isAddSubjectFormOpen, setAddSubjectFormOpen] = useState(false);
  const [isEditSubjectFormOpen, setEditSubjectFormOpen] = useState(false);
  const [selectedSubject, setSelectedSubject] = useState(null);
  const [profilePictures, setProfilePictures] = useState({});
  const location = useLocation();
  const navigate = useNavigate();
  const newImgFile = useRef(null);
  const user = useSelector((state) => state.userReducer.user);
  const handleQuestionsButtonClick = (subjectId ) => {

   console.log("subject id",subjectId)
    navigate(`questionsBank/${subjectId}`, {
      state: {
        syllabusId:syllabusId,
        subjectId:subjectId
      },
    });
  };

  const loadSubjects = async () => {
    setLoading(true);
    try {
      const subjects = await getSubjectsForASyllabus(syllabusId);
      console.log('subjects:', subjects.data);
      const subjectsWithProfilePictures = subjects.data.map(subject => ({
        ...subject,
        profileurl: subject.profileurl // Assuming 'profileurl' exists in the subject object
      }));
      
      // Update items state with subjects including profile picture URLs
      setItems(subjectsWithProfilePictures);
      setItems(subjects.data || []);
       
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadSubjects();
    
  }, [syllabusId]);

  

const handleProfilePictureChange = async (event, subjectId,index) => {
    const file = event.target.files[0];
    if (file) {
      try {
        const response = await uploadImage(file);
        const imageUrl = response.data;
        console.log(response)
        setProfilePictures({ ...profilePictures, [subjectId]: imageUrl });
        const updatedItems = [...items];
      updatedItems[index] = { ...updatedItems[index], profileurl: imageUrl };
      setItems(updatedItems);
      
        const updatedSubject = {
          profileurl:imageUrl
        }
        const updateProfile = await updateSubject(subjectId,updatedSubject)
         

        
      } catch (error) {
        console.error('Error uploading image:', error);
      }
    }
  };

  const handleMenuOpen = (event, subject) => {
    setAnchorEl(event.currentTarget);
    setSelectedSubject(subject);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleAddSubjectClick = () => {
    setAddSubjectFormOpen(true);
  };

  const handleAddSubjectFormClose = () => {
    setAddSubjectFormOpen(false);
  };

  const handleEditSubjectFormClose = () => {
    setEditSubjectFormOpen(false);
  };

  const handleEditSubjectClick = () => {
    setEditSubjectFormOpen(true);
    handleMenuClose();
  };

  const handleDeleteSubject = async () => {
    try {
      if (selectedSubject) {
        await deleteSubject(syllabusId, selectedSubject.id);
        const updatedSubjects = items.filter((subject) => subject.id !== selectedSubject.id);
        setItems(updatedSubjects);
        console.log('Subject deleted successfully');
      }
      handleMenuClose();
    } catch (error) {
      console.error('Error deleting subject:', error);
    }
  };

  const onUltimateQuestionButtonClick =(subjectId)=>{
    console.log("subject id", subjectId);
    navigate(`/dashboard/syllabuses/selectedSyllabus/ultimateQuestionsBank/${subjectId}`,{
      state: {
        syllabusId:syllabusId,
        subjectId: subjectId
      
      },
    })
  }

  const onPastPapersButtonClick =(subjectId)=>{
    navigate(`/dashboard/syllabuses/selectedSyllabus/pastPapers/${subjectId}`,{
      state: {
        syllabusId:syllabusId,
      },
    })
  }

  const onQuizButtonClick =(subjectId)=>{
    navigate(`/dashboard/syllabuses/selectedSyllabus/quizPage/${subjectId}`,{
      state: {
        syllabusId:syllabusId,
      },
    })
  }

  const handleSubjectTopicButtonClick = () => {
    const syllabusId1 = syllabusId;
    navigate('/dashboard/syllabuses/selectedSyllabus/subjectTopics', {
      state: {
        subjectId: selectedSubject.id,
        syllabusId: syllabusId1,
      },
    });
  };

  const buttonStyle ={
    backgroundColor: '#87BCDE', 
    color: 'white',
     margin: '4px', 
     flex: 1,
     minWidth: '90px' 
  }

  const userHasRole = (user, role) => {
    return user.roles && user.roles.includes(role);
  };

  const handlePressPdfQuestionPapers = (subjectId) => {
    navigate(`/dashboard/syllabuses/selectedSyllabus/subject/${subjectId}/question-papers`,{
      state: {
        syllabusId:syllabusId,
      },
    })
  }

  const handleClickOnClassifyQuestions = (subjectId) => {
    navigate(`/dashboard/syllabuses/selectedSyllabus/subject/${subjectId}/calasify-questions`,{
      state: {
        syllabusId:syllabusId,
      },
    })
  }

  const isTyper = userHasRole(user, 'TYPER');

  return (
    <div>
      {loading && <CircularProgress style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} />}
      <Grid container spacing={2} style={{ opacity: loading ? 0.5 : 1, pointerEvents: loading ? 'none' : 'auto' }}>
        <Grid item xs={12}>
          <Button variant="contained" color="primary" onClick={handleAddSubjectClick} style={{ marginTop: '16px' }}>
            Add Subject
          </Button>
        </Grid>

        {items.map((subject, index) => (
          <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
            <Card elevation={3} style={{ padding: '16px', minHeight: '160px', position: 'relative' }}>
              <CardContent>
                <div style={{ position: 'relative' }}>
                  <input
                    type="file"
                    accept="image/*"
                    style={{ display: 'none' }}
                    onChange={(event) => handleProfilePictureChange(event, subject.id,index)}
                    id={`upload-profile-${subject.id}`}
                  />
                      <label htmlFor={`upload-profile-${subject.id}`}>
                        {subject.profileurl ? (
                          <Avatar
                            src={subject.profileurl}
                            alt={subject.name.charAt(0).toUpperCase()}
                            style={{ width: '50px', height: '50px', cursor: 'pointer' }}
                          />
                        ) : (
                          <Avatar
                            alt={subject.name.charAt(0).toUpperCase()}
                            style={{
                              width: '50px',
                              height: '50px',
                              cursor: 'pointer',
                              backgroundColor: '#ccc',
                            }}
                          >
                            {subject.name.charAt(0).toUpperCase()}
                          </Avatar>
                        )}
                      </label>


                </div>
                <Typography variant="h2" style={{ marginTop: '8px' }}>{subject.name}</Typography>
                {subject.subjectGrade && subject.subjectGrade.nameTags && (
                  <div style={{ marginTop: '8px' }}>
                    {subject.subjectGrade.nameTags.map((nameTag, index) => (
                      <Chip key={index} label={nameTag.name} style={{ marginRight: '8px', marginBottom: '8px' }} />
                    ))}
                  </div>
                )}
                <div style={{ display: 'flex', flexDirection: 'row', 
                  marginTop: '16px', 
                  justifyContent: 'space-between',
                  flexWrap: 'wrap'
                  }}>
                  <Button onClick={() => handleQuestionsButtonClick(subject.id)} style={buttonStyle}>
                    Questions
                  </Button>
                  

                {!isTyper && <Button onClick={() => onUltimateQuestionButtonClick(subject.id)} style={buttonStyle}>
                  Ultimate Questions
                </Button>}

                {!isTyper && <Button onClick={() => onPastPapersButtonClick(subject.id)} style={buttonStyle}>
                  Past Papers 
                </Button>}
                
                {!isTyper && <Button onClick={() => onQuizButtonClick(subject.id)} style={buttonStyle}>
                  Daily Quiz 
                </Button>}

                {!isTyper && <Button onClick={() => handlePressPdfQuestionPapers(subject.id)} style={buttonStyle}>
                  pdf Quetion papers 
                </Button>}

                {!isTyper && <Button onClick={() => handleClickOnClassifyQuestions(subject.id)} style={buttonStyle}>
                  Clasify questions 
                </Button>}

                </div>
              </CardContent>
               
              <div style={{ position: 'absolute', top: 0, right: 0 }}>
              {!isTyper &&<IconButton onClick={(event) => handleMenuOpen(event, subject)}>
                <MoreHorizIcon />
              </IconButton>}
                <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
                  <MenuItem onClick={handleSubjectTopicButtonClick}>
                    <ReadMoreIcon style={{ fontSize: '25px', marginRight: '8px' }} />
                    Topics
                  </MenuItem>
                  <MenuItem onClick={handleEditSubjectClick}>
                    <EditIcon style={{ fontSize: '16px', marginRight: '8px' }} />
                    Edit
                  </MenuItem>
                  <MenuItem onClick={handleDeleteSubject}>
                    <DeleteIcon style={{ fontSize: '16px', marginRight: '8px' }} />
                    Delete
                  </MenuItem>
                </Menu>
              </div>
            </Card>
          </Grid>
        ))}
      </Grid>

      <AddSubjectForm
        setItems={setItems}
        open={isAddSubjectFormOpen}
        onClose={handleAddSubjectFormClose}
        onAddSubject={handleAddSubjectFormClose}
        syllabusId={syllabusId}
        selectedLanguages={selectedLanguages}
        loadSubjects={loadSubjects}
      />

      {isEditSubjectFormOpen && selectedSubject && (
        <EditSubjectForm
          open={isEditSubjectFormOpen}
          onClose={handleEditSubjectFormClose}
          syllabusId={syllabusId}
          subjectData={selectedSubject}
          setItems={setItems}
          selectedLanguages={selectedLanguages}
          loadSubjects={loadSubjects}
        />
      )}
    </div>
  );
}

export default SubjectsPage;
